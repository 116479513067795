<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import { Form, PositionTable, CustomerPortrait } from '../form';
import Modal from '../../../../../components/modal';
import request from '../../../../../utils/request';
import Submitapproval from '../form/components/submit_approve/index.vue';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
    PositionTable,
    Submitapproval,
    CustomerPortrait,
  },
  data() {
    return {
      // requestUrl: '/mdm/mdmTerminalController/page',
      params: {
        code: '',
      },
      configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      this.params.code = val;
      this.getList();
    },
  },
  created() {
    this.getConfigList('terminal_manage');
  },
  methods: {
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    // 控制按钮显示
    clickVisible({ row, val: { code } }) {
      // 审批通过 approved
      // 审批中 approving
      // 活动关闭 closed
      // 流程追回 interrupt
      // 驳回 rejected
      const { actApproveStatus, processCode } = row;
      if (actApproveStatus && code === 'submit_approval' && (actApproveStatus === '6' || actApproveStatus === '2' || actApproveStatus === '3')) {
        return false;
      }
      if (actApproveStatus && code === 'edit' && (actApproveStatus !== '3' && actApproveStatus !== '4')) { // 审批中不能编辑
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      this.formName = 'Form';
      this.formConfig = { ...val };
      if (val.code === 'edit') {
        this.formConfig = {
          ...val,
          ...row,
        };
        this.modalConfig.title = '编辑';
        this.openFull();
      } else if (val.code === 'organization_delete') {
        this.deleteClick({ val: row });
      } else if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig.id = '';
        this.openFull();
      } else if (val.code === 'view') {
        this.formConfig = {
          ...val,
          ...row,
        };
        this.formConfig.code = 'view';
        this.modalConfig.title = '查看';
        this.openFull();
      } else if (val.code === 'available') {
        const selection = [];
        this.selectRow.forEach((v) => {
          selection.push(v.terminalCode);
        });
        if (selection.length === 0) {
          this.$message('请选择一条数据');
          return false;
        }
        request.post(val.apiUrl, selection).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${val.buttonName}成功`,
            });
            this.getList();
          }
        });
      } else if (val.code === 'submit_approval') {
        this.formName = 'Submitapproval';
        this.formConfig = { ...val, row };
        this.modalConfig.title = '提交审批';
        this.modalConfig.width = 1000;
        this.openFull();
      } else if (val.code === 'customer_portrait') {
        this.formName = 'CustomerPortrait';
        this.formConfig = row;
        this.modalConfig.title = '客户画像';
        this.openFull();
      }
    },
  },
};
</script>
