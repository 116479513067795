<template>
  <div>
    <div class="terminalInfo-title">联系人信息  <el-button v-if="!disabled" icon="el-icon-plus add-btn" circle @click="()=>this.addContact()"></el-button></div>
      <div v-for="(item,index) in dataList"  class = "terminalInfo-contant-box" :key="index">
        <div class = "terminalInfo-contant">
          <div class = "terminalInfo-contact-item">
            <span class="terminalInfo-contact-item-title">联系人{{index+1}}姓名</span>
            <el-input
              placeholder="请输入内容"
              :disabled = 'disabled'
              v-model="item.contactName"
              @input="()=>onChange()"
              clearable>
            </el-input>
          </div>
          <div class = "terminalInfo-contact-item">
            <span class="terminalInfo-contact-item-title"> 联系人{{index+1}}电话</span>
            <el-input
              placeholder="请输入内容"
              :disabled = 'disabled'
              v-model="item.contactPhone"
               @input="()=>onChange()"
              clearable>
            </el-input>
          </div>
          <div class = "terminalInfo-contact-item">
            <span class="terminalInfo-contact-item-title">是否主联系人</span>
            <el-select :disabled = 'disabled' v-model="item.contactMain" @change="(value)=>onChange('contactMain',index,value)" placeholder="请选择" style="width: 100%;">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div v-if="index!== 0 && !disabled" class="terminalInfo-contant-delete" >
          <i class="el-icon-delete" @click="()=>deleteContant(index)"></i>
        </div>

    </div>

   </div>
</template>

<script>

export default {
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  name: 'ContactUser',
  data() {
    return {
      dataList: [],
      options: [{ value: '1', label: '是' }, { value: '0', label: '否' }],
    };
  },
  watch: {
    value() {
      this.dataList = this.value;
    },
  },

  created() {
    this.dataList.push({ contactName: '', contactPhone: '', contactMain: '1' });
  },
  methods: {
    addContact() {
      this.dataList.push({ contactName: '', contactPhone: '', contactMain: '0' });
    },
    deleteContant(index) {
      this.dataList.splice(index, 1);
      this.$emit('input', this.dataList);
    },
    onChange(type, index, value) {
      console.log(type, index, value);
      if (type === 'contactMain' && value === '1') {
        const arr = this.dataList.map((v, i) => {
          const item = v;
          if (i !== index) {
            item.contactMain = '0';
          }
          return item;
        });
        console.log('arr', arr);
        this.dataList = arr;
      }

      this.$emit('input', this.dataList);
    },
  },
};

</script>

<style lang = "less" scoped>
  .add-btn{
    padding: 5px!important;
  }
  /deep/.el-button--small.is-circle{
    padding: 5px;
  }
  /deep/.terminalInfo-contact-item-title{
    padding: 0px 10px;
    text-align: right;
  }
  .terminalInfo-title{
    border-top:1px solid #AEAEAE;
    padding-top:0.5rem;
    margin-bottom: 0.5rem;
  }
  .terminalInfo-contant-box{
    margin-bottom: 19px;
    display: flex;
    padding-left: 2.5rem;
    box-sizing: border-box;
    .terminalInfo-contant{
      display: flex;
      width: 95%;
      .terminalInfo-contact-item{
        flex:1;
        display: flex;
        .terminalInfo-contact-item-title{
          width: 125px;
          color:#606266;
        }
      }
    }
  }

</style>
